import React, { Fragment } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default ({ location: { pathname } }) => (
    <Layout>
        <SEO
            title="Kontakt | Jämför snuspriser online"
            keywords={['kontakt', 'billigt', 'snus', 'billigprilla.se']}
            pathname={pathname}
        />
        <h1 className="text-4xl mb-2">Kontakt</h1>
        <form
            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
            name="contact"
            method="POST"
            action="/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
        >
            <SpamFields />
            <InputField id="namn" placeholder="Namn" title="Namn" />
            <InputField id="email" placeholder="E-post" title="E-post" />
            <InputField id="meddelande" title="Meddelande" textarea />
            <div className="flex items-center justify-between">
                <button
                    className="w-full bg-black hover:bg-secondary text-white font-bold py-2 px-4 rounded"
                    type="submit"
                >
                    Skicka
                </button>
            </div>
        </form>
    </Layout>
);

const InputField = ({ id, placeholder = false, textarea = false, title }) => {
    return (
        <div className="mb-4">
            <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor={id}
            >
                {title}
            </label>
            {!textarea ? (
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                    id={id}
                    name={id}
                    type="text"
                    placeholder={placeholder}
                    required
                />
                ) : (
                <textarea
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                    id={id}
                    name={id}
                    rows="6"
                    required
                />  
            )}
        </div>
    )
}

const SpamFields = () => (
    <Fragment>
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
    </Fragment>
);
